import { isServer, getStoryCategory } from 'utils'
import type { Story } from 'api/types'
import CryptoJS from 'crypto-js'
import { getSupplySideProperties } from '@barstoolsports/web-sdk'

export const IN_ARTICLE_AD_LIMIT = 4
export const DOMAIN = process.env.SITE_URL ?? 'https://www.barstoolsports.com'

export const isAdProviderEnabled = (provider: string) => {
  switch (provider) {
    case 'hashtag-labs':
      return process.env.AD_PROVIDERS_ENABLED === 'all' || process.env.AD_PROVIDERS_ENABLED === 'hashtag-labs'
    case 'nativo':
      return process.env.AD_PROVIDERS_ENABLED === 'all' || process.env.AD_PROVIDERS_ENABLED === 'nativo'
    case 'spotim':
      return process.env.AD_PROVIDERS_ENABLED === 'all' || process.env.AD_PROVIDERS_ENABLED === 'spotim'
    default:
      throw new Error('Invalid value for AD_PROVIDERS_ENABLED')
  }
}

export const shouldPageRenderAds = (path: string) => {
  return !['barstool-sportsbook', 'hockeyheroes', 'the-barstool-fund', 'partnerships', 'email-preferences'].includes(
    path.split('/')[1]
  )
}

export const isAdBlockEnabled = function () {
  if (isServer) {
    return false
  }

  if (window.BS_ADBLOCK !== undefined) {
    return window.BS_ADBLOCK
  }

  let blockedElement = document.createElement('div')
  blockedElement.className = 'pub_300x250 pub_300x250m pub_728x90 text-ad textAd text_ad text_ads text-ads'
  blockedElement.setAttribute('style', 'position: absolute; top: -10px; left: -10px; width: 1px; height: 1px;')
  document.body.appendChild(blockedElement)

  window.BS_ADBLOCK =
    window.document.body.getAttribute('abp') != null ||
    blockedElement.offsetParent === null ||
    blockedElement.offsetHeight === 0 ||
    blockedElement.offsetLeft === 0 ||
    blockedElement.offsetTop === 0 ||
    blockedElement.offsetWidth === 0 ||
    blockedElement.clientHeight === 0 ||
    blockedElement.clientWidth === 0

  return window.BS_ADBLOCK
}

export const getVerityKeyValues = (story: Story) => {
  const threats = story?.verity?.threats?.map((threat) => ({
    [`verity_${threat.id.toLowerCase()}_conf`]: threat.confidence,
    [`verity_${threat.id.toLowerCase()}_risk`]: threat.confidence
  }))

  let keyValues = {
    verity_keywords: story?.verity?.keywords,
    verity_iabv1: story?.verity?.iab?.v1?.map((v1) => v1.id) ?? [],
    verity_iabv2: story?.verity?.iab?.v2?.map((v2) => v2.id) ?? [],
    verity_neutral: story?.verity?.sentiments?.find(({ sentiment }) => sentiment === 'neutral')?.score,
    verity_positive: story?.verity?.sentiments?.find(({ sentiment }) => sentiment === 'positive')?.score,
    verity_negative: story?.verity?.sentiments?.find(({ sentiment }) => sentiment === 'negative')?.score
  }

  if (threats?.length) {
    keyValues = threats.reduce((acc, obj) => ({ ...acc, ...obj }), keyValues)
  }

  return keyValues
}

export const getStoryKeyValues = (story: Story) => {
  let keyValues = {
    authorId: story?.author?.id,
    category: getStoryCategory(story),
    tags: story?.tags?.length ? story.tags : null,
    brandName: story?.brand_name,
    brandId: story?.brand_id,
    pagetype: story?.type,
    ...(story?.nsfw && { tags: [...(story?.tags || []), 'NSFW'] }),
    ...getVerityKeyValues(story)
  }
  return keyValues
}

export const getSupplySidePlatformKeyValues = (clientData: any, user: any, story: Story) => {
  const sitename = 'Barstool Sports'
  const categories = story?.verity?.iab?.v3?.map((c) => c.id) || []
  const md5Email = user?.email ? CryptoJS.MD5(user?.email ?? '').toString() : ''
  const sha256Email = user?.email ? CryptoJS.SHA256(user?.email ?? '').toString() : ''

  const duration = story?.post_type_meta?.podcast?.duration ?? story?.media?.video?.duration ?? null

  const props = {
    name: sitename,
    domain: DOMAIN,
    kwarray: [
      'barstool sports',
      'sports',
      'comedy',
      'lifestyle',
      'entertainment',
      'pizza',
      'portnoy',
      'pardon my take'
    ],
    categories,
    bcat: [1002, 1361, 1474, 1000],
    cat: ['JLBCU7', 370, 371, 483, 646, 'TIFQA5', 432, 440], // Site-wide IAB categories
    ...(story?.id
      ? {
          content: {
            id: String(story?.id),
            title: story?.title,
            ...(story?.brand_name && { series: story?.brand_name }),
            url: story?.url,
            cat: categories, // Content-specific IAB categories from GumGum/Verity
            context: story?.type === 'standard_post' ? 6 : 1, // Context of content - 6 == article, 1 == other
            kwarray: story?.verity?.keywords, // Keywords from GumGum/Verity
            livestream: story?.post_type_meta?.live ? 1 : 0,
            len: duration ?? undefined
          }
        }
      : {}),
    device: {
      ...clientData
    },
    user: {
      md5Email,
      sha256Email
    }
  }

  return getSupplySideProperties(props)
}

export const clearAdTargetingOnLogout = () => {
  window?.apstag?.dpa()
}
